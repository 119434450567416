import { AsYouType } from "libphonenumber-js"
import { trim as trimAll } from "lodash"

export const identity = value => value

export const valueOrNull = value => value || null

export const positiveFloatNumber = value =>
  value === undefined || value === "" ? "" : `${value}`.replace(/[^(\d+).(\d+)]/g, "")

export const capitalize = value =>
  !value
    ? ""
    : value
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")

export const trim = value => (!value ? "" : trimAll(value))

export const formatZip = value => {
  if (!value) {
    return null
  }

  const onlyNums = `${value}`.replace(/[^\d]/g, "")
  if (onlyNums.length > 5) {
    return onlyNums.slice(0, 5) + "-" + onlyNums.slice(5, 9)
  }

  return onlyNums
}

export const formatPhone = value => {
  if (!value) {
    return ""
  }
  const startsWithPlus = value[0] === "+"
  const onlyNums = value.replace(/[^\d]/g, "")
  if (onlyNums.length <= 3) {
    return startsWithPlus ? `+${onlyNums}` : onlyNums
  }
  const ayt = startsWithPlus ? new AsYouType() : new AsYouType("US")
  return ayt.input(value)
}

export const formatMoney = value =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value)

export const formatStrToFloatWithRound = (value, fixed = 2) => {
  return parseFloat(Number(value).toFixed(fixed))
}

export const capitalizeFirstLetter = string => {
  if (!string) return ""
  return string.charAt(0).toUpperCase() + string.slice(1)
}
