import { ReactComponent as ArrowLeft } from "@/assets/images/arrow-left.svg"
import { ReactComponent as ArrowRight } from "@/assets/images/arrow-right.svg"
import { Button, Card, CardContent, Grid, IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material"
import { map } from "lodash"
import moment from "moment"
import React, { forwardRef, useCallback, useEffect, useState } from "react"
import ReactDOM from "react-dom"

import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import { ReactComponent as EmptyList } from "../../assets/images/icon_empty_calendar.svg"
import { INTERVIEW_CALENDAR_MAX_TIME, INTERVIEW_CALENDAR_MIN_TIME, MEDIA_MD_BREAKPOINT } from "../../common/constants"
import { formatDateKeepZone } from "../../helpers/dates"
import ScheduleSessionButton from "./ScheduleSessionButton"

const transformToDate = date => formatDateKeepZone(date, "YYYY-MM-DDTHH:mm:ss")

const PLUGINS = [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]

const VIEW = Object.freeze({
  MOBILE: "listWeek",
  DESKTOP: "dayGridWeek",
})

const columnHeaderHtml = date => {
  return `<div class="fc-header-text">
      <span class="fc-header-day-number">${formatDateKeepZone(date, "DD")}</span>
      <span class="fc-header-day-title">${formatDateKeepZone(date, "dddd")}</span>
    </div>`
}

const EventContent = ({ event }) => {
  const { extendedProps = {}, title, start, end } = event
  const { is_overdue: isOverdue } = extendedProps
  return (
    <Tooltip title={title}>
      <div className="fc-content">
        <div className="fc-time">{`${formatDateKeepZone(start, "hh:mma")}${
          end ? ` – ${formatDateKeepZone(end, "hh:mma")}` : ""
        }`}</div>
        <div className="fc-title">
          {title}
          {isOverdue && <span className="overdue-status"> Overdue</span>}
        </div>
      </div>
    </Tooltip>
  )
}

const eventNewDiv = ({ event, el }) => {
  // Creating `div` to replace the default <a href=""/> for event
  const eventDiv = document.createElement("div")
  // Get classes on the default `a.fc-timeline-event`
  const classes = Array.from(el.classList)
  // Add classes to the new `div`
  eventDiv.classList.add(...classes)

  ReactDOM.render(<EventContent event={event} />, eventDiv)

  return eventDiv
}

const Schedule = forwardRef(function Schedule({ events, defaultDay, ...params }, ref) {
  const onSelectEvent = ({ event }) => {
    if (event.extendedProps.link) {
      window.open(event.extendedProps.link, "_blank")
    }
  }

  return (
    <FullCalendar
      ref={ref}
      plugins={PLUGINS}
      header={false}
      allDaySlot={false}
      navLinks={false}
      events={events}
      eventClick={onSelectEvent}
      defaultDate={defaultDay}
      minTime={INTERVIEW_CALENDAR_MAX_TIME}
      maxTime={INTERVIEW_CALENDAR_MIN_TIME}
      eventRender={eventNewDiv}
      height="auto"
      {...params}
    />
  )
})

export const DesktopWeekSchedule = ({ title, loading, events, week, setWeek, disableScheduleSessionButton }) => {
  const showNextWeek = () => {
    const nextWeek = moment.parseZone(week).add(1, "week").toDate()
    setWeek(nextWeek)
  }
  const showPreviousWeek = () => {
    const prevWeek = moment.parseZone(week).subtract(1, "week").toDate()
    setWeek(prevWeek)
  }
  const showCurrentWeek = () => {
    const currentWeek = moment.parseZone(new Date()).toDate()
    setWeek(currentWeek)
  }

  return (
    <>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1} mb={2} alignItems="center" justifyContent="flex-end">
            <Grid item>
              <IconButton
                aria-label="Previous week"
                onClick={showPreviousWeek}
                sx={{
                  width: "50px",
                  height: "50px",
                  minWidth: "50px",
                  color: "#9c7f28",
                  borderColor: "#fdf5dc",
                  backgroundColor: "#fdf5dc",
                }}
              >
                <ArrowLeft />
              </IconButton>
              <Button
                variant="outlined"
                size="small"
                onClick={showCurrentWeek}
                sx={{ mx: 1, color: "#9c7f28", borderColor: "#fdf5dc", backgroundColor: "#fdf5dc" }}
              >
                Current
              </Button>
              <IconButton
                aria-label="Next week"
                onClick={showNextWeek}
                sx={{
                  width: "50px",
                  height: "50px",
                  color: "#9c7f28",
                  borderColor: "#fdf5dc",
                  backgroundColor: "#fdf5dc",
                }}
              >
                <ArrowRight />
              </IconButton>
            </Grid>
            {!disableScheduleSessionButton && (
              <Grid item>
                <ScheduleSessionButton />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        className="dashboard_schedule -desktop"
        sx={{
          border: "1px solid #f0f0f0",
          borderRadius: "5px",
        }}
      >
        {loading ? (
          <div className="dashboard_schedule_loader">
            <div className="spinner-wrapper">
              <div className="spinner-border text-primary" />
            </div>
          </div>
        ) : (
          <>
            <Schedule
              defaultDay={week}
              events={events}
              defaultView={VIEW.DESKTOP}
              columnHeaderHtml={columnHeaderHtml}
              slotDuration="0:30:00"
            />
            {events.length === 0 && (
              <div className="dashboard_schedule_empty dashboard_empty_list">
                <EmptyList />
                <div className="dashboard_empty_list-text mt-4">
                  <span className="dashboard_empty_list-text -bold">You don’t have any sessions this week</span>
                  <span>Check the opportunity below.</span>
                </div>
              </div>
            )}
          </>
        )}
      </Grid>
    </>
  )
}

const MobileSchedule = ({ title, events, week, calendarPath, disableScheduleSessionButton }) => {
  return (
    <div className="mb-3 align-items-center">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" className="px-4">
            {title}
          </Typography>
        </Grid>
      </Grid>
      {!disableScheduleSessionButton && (
        <Grid container spacing={2} className="pb-2 pt-2 px-4 bg-white">
          <Grid item xs={12}>
            <ScheduleSessionButton />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} className="panel dashboard_schedule -mobile mb-4">
        <Schedule
          defaultDay={week}
          events={events}
          defaultView={VIEW.MOBILE}
          listDayAltFormat={{
            day: "2-digit",
            month: "2-digit",
          }}
        />
        <Grid item xs={12} className="text-center font-weight-medium py-1">
          <a href={calendarPath}>View calendar</a>
        </Grid>
      </Grid>
    </div>
  )
}

const WeekSchedule = ({ title, loading, events: list, week, setWeek, calendarPath, disableScheduleSessionButton }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"))
  const [view, setView] = useState(isMobile ? VIEW.MOBILE : VIEW.DESKTOP)

  const handler = useCallback(() => {
    const newView = document.body.offsetWidth < MEDIA_MD_BREAKPOINT ? VIEW.MOBILE : VIEW.DESKTOP
    if (view !== newView) setView(newView)
  }, [view])

  const events = map(list, event => {
    const eventTypeClass = event.view === "interview" ? "-interview" : "-session"
    const eventOrganizationClass = event.organization_id ? "-with-organization" : "-no-organization"
    return {
      ...event,
      start: transformToDate(event.start),
      end: transformToDate(event.end),
      overlap: false,
      classNames: ["max-width-100", eventTypeClass, eventOrganizationClass],
      extendedProps: {
        link: event.link,
        is_overdue: event.is_overdue,
      },
    }
  })

  useEffect(() => {
    handler()
    window.addEventListener("resize", handler)
    return () => {
      window.removeEventListener("resize", handler)
    }
  }, [handler])

  return view === VIEW.MOBILE ? (
    <Card sx={{ p: 2, boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)" }}>
      <CardContent>
        <MobileSchedule
          title={title}
          events={events}
          week={week}
          calendarPath={calendarPath}
          disableScheduleSessionButton={disableScheduleSessionButton}
        />
      </CardContent>
    </Card>
  ) : (
    <Card sx={{ p: 2, boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)" }}>
      <CardContent>
        <DesktopWeekSchedule
          title={title}
          events={events}
          week={week}
          setWeek={setWeek}
          loading={loading}
          disableScheduleSessionButton={disableScheduleSessionButton}
        />
      </CardContent>
    </Card>
  )
}

export default WeekSchedule
