import { ReactComponent as EyeIcon } from "@/assets/images/eye.svg"
import { ReactComponent as TrainingCenterIcon } from "@/assets/images/training_center.svg"
import LoadingSpinner from "@/common/loading"
import { UpcomingSession } from "@/components/UpcomingSession"
import { formatDate } from "@/helpers/dates"
import { Box, Link as MuiLink, Stack, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import Grid from "@mui/material/Unstable_Grid2"
import React, { useEffect, useMemo, useState } from "react"
import AdminMessage from "./AdminsMessage"
import { useGetAdminMessageRequest, useGetUpcomingSession, useTutorScheduleRequest } from "./hooks"
import { NextSession } from "./NextSession"
import { OpportunitiesList } from "./OpportunitiesList"
import { TutorDashboardProvider } from "./providers"
import { UserDashboard } from "./UserDashboard"
import WeekSchedule from "./WeekSchedule"

// Links Configuration
const dashboardLinks = [
  {
    Icon: EyeIcon,
    url: "https://docs.google.com/document/d/11XBrI-KHUp_DzgO13y8TSU3Lh1sqKZ4vZjamzI-IZ9o/export?format=pdf",
    label: "Guide to Braintrust",
  },
  {
    Icon: TrainingCenterIcon,
    url: "https://k12.instructure.com/enroll/A7TDKC",
    label: "Training Center",
  },
]

// Styled Components
const TitleTypography = styled(Typography)`
  color: #333;
  letter-spacing: -0.04em;
`

const IconContainer = styled("div")`
  width: 24px;
  height: 24px;
  fill: #666;
`

const StyledLink = styled(MuiLink)`
  color: #333;
  text-decoration: none;

  &:hover {
    color: #000;
  }
`

// Component to render individual link
const DashboardLink = ({ Icon, url, label }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <IconContainer>
      <Icon />
    </IconContainer>
    <StyledLink href={url} target="_blank" rel="noreferrer">
      {label}
    </StyledLink>
  </Stack>
)

// Panel to display all links
const LinksPanel = () => (
  <Box className="panel panel_body w-100 p-4">
    <Stack spacing={2}>
      {dashboardLinks.map((link, index) => (
        <DashboardLink key={index} {...link} />
      ))}
    </Stack>
  </Box>
)

// Main Dashboard Content Component
const TutorDashboardContent = ({ currentUser, calendarPath }) => {
  const { first_name: userFirstName, id: tutorId, feature_flags: featureFlags } = currentUser
  const [selectedDate, setSelectedDate] = useState(() => formatDate(new Date()))

  const handleDateChange = date => {
    setSelectedDate(formatDate(date))
  }

  const { loading: isUpcomingSessionLoading, data: upcomingSessionData } = useGetUpcomingSession({ tutorId })

  const { loading: isAdminMessageLoading, data: adminMessageData } = useGetAdminMessageRequest()

  const {
    loading: isTutorScheduleLoading,
    data: tutorScheduleEvents,
    request: fetchTutorSchedule,
  } = useTutorScheduleRequest()

  useEffect(() => {
    fetchTutorSchedule(selectedDate)
  }, [fetchTutorSchedule, selectedDate])

  const isGlobalLoading = useMemo(
    () => isUpcomingSessionLoading || isAdminMessageLoading || isTutorScheduleLoading,
    [isUpcomingSessionLoading, isAdminMessageLoading, isTutorScheduleLoading]
  )

  const shouldDisplayCurriculumSession = useMemo(
    () =>
      featureFlags["curriculum_enabled"] &&
      upcomingSessionData?.next_session?.is_curriculum_program &&
      upcomingSessionData?.next_session?.assignment_action_items,
    [featureFlags, upcomingSessionData]
  )

  const hasUpcomingSession = useMemo(
    () => upcomingSessionData?.next_session && Object.keys(upcomingSessionData.next_session).length > 0,
    [upcomingSessionData]
  )

  if (isGlobalLoading) {
    return <LoadingSpinner />
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4} sx={{ pt: 2 }}>
        <TitleTypography variant="h3" sx={{ ml: 2 }}>
          Welcome back, {userFirstName}!
        </TitleTypography>
        {hasUpcomingSession && (
          <Grid xs={12} className="container panel-wrapper" sx={{ px: 0 }}>
            {shouldDisplayCurriculumSession ? (
              <UpcomingSession upcomingSession={upcomingSessionData.next_session} />
            ) : (
              <NextSession tutorId={tutorId} />
            )}
          </Grid>
        )}
        <Grid xs={12} className="container panel-wrapper" sx={{ px: 0 }}>
          <WeekSchedule
            title="Week Schedule"
            loading={isTutorScheduleLoading}
            week={selectedDate}
            setWeek={handleDateChange}
            events={tutorScheduleEvents}
            calendarPath={calendarPath}
          />
        </Grid>
        <Grid xs={12} container spacing={2}>
          <Grid xs={12} className="dashboard_tutor_message -mobile">
            <AdminMessage message={adminMessageData} />
            <LinksPanel />
          </Grid>
          <Grid xs={8} className="container panel-wrapper">
            <OpportunitiesList />
          </Grid>
          <Grid xs={4} className="container panel-wrapper dashboard_tutor_message -desktop">
            <Box className="dashboard_tutor_message_wrapper">
              <Stack spacing={2}>
                <UserDashboard />
                <AdminMessage message={adminMessageData} />
                <LinksPanel />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

// Main Dashboard Wrapper with Context Provider
const TutorDashboard = props => (
  <TutorDashboardProvider>
    <TutorDashboardContent {...props} />
  </TutorDashboardProvider>
)

export default TutorDashboard
