import { Divider, Link, Stack, Typography } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"

const humanizeString = str => {
  if (!str || typeof str !== "string") return ""
  return str
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

const LinkItem = ({ lessonResource }) => {
  const { resource_url, name, resource_type } = lessonResource
  const displayText = humanizeString(name) || humanizeString(resource_type) || "Resource"

  return (
    <Link
      href={resource_url}
      variant="body2"
      target="_blank"
      rel="noopener noreferrer"
      sx={{ overflowWrap: "break-word" }}
      aria-label={`Resource link: ${displayText}`}
      underline="hover"
      color="primary"
    >
      {displayText}
    </Link>
  )
}

LinkItem.propTypes = {
  lessonResource: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    resource_type: PropTypes.string,
    resource_url: PropTypes.string.isRequired,
    show_icon: PropTypes.bool,
  }).isRequired,
}

const LessonTitle = ({ lesson, level }) => {
  if (!lesson.lesson_number) {
    return lesson.name
  }

  const lessonPrefix = level ? `Lesson ${level}.` : ""

  return (
    <Typography variant="subtitle1">
      <span style={{ fontWeight: "bold" }}>{lessonPrefix + lesson.lesson_number}:</span> {lesson.name}
    </Typography>
  )
}

const LessonsList = ({ lessons = [], level }) => (
  <Stack divider={<Divider />} spacing={2}>
    {lessons.map(lesson => (
      <Stack key={lesson.id} spacing={1} sx={{ p: 2, pl: 3 }}>
        <LessonTitle lesson={lesson} level={level} />
        {lesson.description && (
          <Typography variant="body2" color="text.primary">
            {lesson.description}
          </Typography>
        )}
        {(lesson.lesson_resources?.length > 0 || lesson.url) && (
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight="500">
              Resources:
            </Typography>
            <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
              {lesson.lesson_resources?.map(lessonResource => (
                <LinkItem key={lessonResource.id} lessonResource={lessonResource} />
              ))}
              {lesson.url && (
                <LinkItem
                  key={`lesson-${lesson.id}`}
                  lessonResource={{
                    id: `lesson-${lesson.id}`,
                    name: "Activity",
                    resource_url: lesson.url,
                  }}
                />
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    ))}
  </Stack>
)

LessonsList.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      lesson_number: PropTypes.number,
      lesson_resources: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string,
          resource_type: PropTypes.string,
          resource_url: PropTypes.string.isRequired,
          show_icon: PropTypes.bool,
        })
      ),
      url: PropTypes.string,
    })
  ),
  level: PropTypes.number,
}

LessonsList.defaultProps = {
  lessons: [],
  level: null,
}

export default React.memo(LessonsList)
